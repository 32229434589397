const __request = require(`./__request/flight-search/__request_contentType_json`)
const buyer_dom_white_pnrImport = (data) => {
    let enableErrorAlert = true;
    if (data.enable_error_alert !== undefined && data.enable_error_alert !== null) {
      enableErrorAlert = data.enable_error_alert
    }
    console.log('enableErrorAlert', enableErrorAlert)
  let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/white/pnrImport',
    data: data,
    enable_error_alert: enableErrorAlert // 临时隐藏
  }
  return __request(pParameter)
}
export default buyer_dom_white_pnrImport
