const __request = require(`./__request/__request_contentType_json`);

// 航班列表查询
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const flights = (data) => {
  // const airLineCode = pParameter.airLineCode || '';
  // const beginDate = pParameter.beginDate || '';
  // const cabinRank = pParameter.cabinRank === null || pParameter.cabinRank === undefined ? '' : pParameter.cabinRank;
  // const currentPage = pParameter.currentPage || '';
  // const directFlight = pParameter.directFlight === null || pParameter.directFlight === undefined ? '' : pParameter.directFlight;
  // const discount = pParameter.discount === null || pParameter.discount === undefined ? '' : pParameter.discount;
  // const eCityPortCode = pParameter.eCityPortCode || '';
  // const flightType = pParameter.flightType || '';
  // const needPnr = pParameter.needPnr || 0;
  // const orderField = pParameter.orderField || '';
  // const orderType = pParameter.orderType || '';
  // const pageSize = pParameter.pageSize || '';
  // const psgType = pParameter.psgType || '';
  // const sCityPortCode = pParameter.sCityPortCode || '';
  // const takeOffInterval = pParameter.takeOffInterval || '';
  // console.log(eCityPortCode)
  var pParameter = {
    method: 'post',
    // urlSuffix: '/flight/domestic/buyer/white/flight/list',
    urlSuffix:'/buyer/dom/white/flights',
    data:data
  }

  return __request(pParameter)
}

module.exports = flights;


