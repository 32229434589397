// 机票选择组件
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/domestic-ticket-airport-selector/1.2.4/index.vue';
// 城市列表查询
const citys = require(`../../../../../lib/data-service/flight/flight_domestic_citys`);
import { internationalCity } from "@/lib/data-service/flight/fligthInternational";
// 国内航空公司查询
const airlines = require(`../../../../../lib/data-service/flight/flight_domestic_airlines`);
// 国内航班列表查询
const flights = require(`../../../../../lib/data-service/flight/flight_domestic_flights`);
import airLineBox from "./../../components/airLine";
import { internationalAirLines } from "@/lib/data-service/flight/fligthInternational";
import noticeList from "./../../components/notice";
const _ = require(`underscore`);
// 获取公告
import get_flight_notice_list from "@/lib/data-service/yinzhilv-temporary-storage-system/get_flight_notice_list";

import buyer_dom_white_pnrImport from "@/lib/data-service/flight/buyer_dom_white_pnrImport";
import moment from 'moment';
import buyer_dom_white_enquiryPnrTextParse from "@/lib/data-service/flight/buyer_dom_white_enquiryPnrTextParse.js";

export default {
    data() {
        return {
            startAirportParams: null,
            endAirportParams: null,

            pickerOptions: this.setEndTime1(),
            // announcementList: noticeList,
            announcementList: [],
            airType: 1, // 航程类型
            PNRtext: "",
            conditionNavActive: 1,
            airTypeShow: true,
            pickerOptions2: this.setEndTime(), //  返回时间
            time1: null, //  出发时间
            time2: null, //  返回时间
            time2Show: true, // 返回时间是否可用
            timeRoll: 1569772800000, //滚动条时间
            starCity: "", // 出发城市
            starCity1: "", // 展示出发城市
            starTwoCity: "", // 第二出发城市
            starTwoCity1: "", // 第二展示出发城市
            endCity: "", // 目的城市
            endCity1: "", // 展示目的城市
            endTwoCity: "", // 第二目的城市
            endTwoCity1: "", // 第二展示目的城市
            cityList: [], // 城市字母列表
            restaurants: [], // 所有城市机场列表
            cityListH: ["热门", "ABCD", "EFGH", "JKLM", "NOPQRS", "TUVWX", "YZ"], // 热门列表
            international: {
                adult: 1,
                adultList: [
                    { value: 1, label: "成人1" },
                    { value: 2, label: "成人2" },
                    { value: 3, label: "成人3" },
                    { value: 4, label: "成人4" },
                    { value: 5, label: "成人5" },
                    { value: 6, label: "成人6" },
                    { value: 7, label: "成人7" },
                    { value: 8, label: "成人8" },
                    { value: 9, label: "成人9" }
                ],
                childList: [
                    { value: 1, label: "儿童1" },
                    { value: 2, label: "儿童2" },
                    { value: 3, label: "儿童3" },
                    { value: 4, label: "儿童4" },
                    { value: 5, label: "儿童5" },
                    { value: 6, label: "儿童6" },
                    { value: 7, label: "儿童7" },
                    { value: 8, label: "儿童8" },
                    { value: 9, label: "儿童9" }
                ],
                babyList: [
                    { value: 1, label: "婴儿1" },
                    { value: 2, label: "婴儿2" },
                    { value: 3, label: "婴儿3" },
                    { value: 4, label: "婴儿4" }
                ],
                rankList: [
                    { value: 1, label: "头等舱" },
                    { value: 2, label: "公务舱" },
                    { value: 3, label: "经济舱" }
                ],
                rank: 3
            }, //国际航空
            HotCity: [], //  热门城市列表
            cityListHShow: 0, //  热门列表index
            starList: false, //  出发城市列表显示
            EndList: false, //  目的城市列表显示
            starTwoList: false, //  出发城市列表显示
            EndTwoList: false, //  目的城市列表显示
            ClassType: 0, //  舱位选择
            ClassTypeList: [
                //  舱位列表
                {
                    value: 0,
                    label: "不限"
                },
                {
                    value: 1,
                    label: "头等舱"
                },
                {
                    value: 2,
                    label: "公务舱"
                },
                {
                    value: 3,
                    label: "经济舱"
                }
            ],
            psgType: 1, //  乘机人类型
            airlines: "", //  航司
            airlinesList: [
                //  航司列表
                {
                    airlineCode: "",
                    airCompanyName: "不限"
                }
            ],
            internationalAirList: [],
            timeIndex: 0, // 已选的class
            flightsList: [], // 航班列表
            flightsIndex: 0, //  当前展示的列表
            flightsShow: false, // 是否展示
            reserveType: "预定", // 按钮状态
            params: {}, // 搜索传参
            internationalCity: [],
            internationalCityHotCity: [],
            internationalCitys: [],

            showPnrTips: true,
            submitLoading: false,

            showSayPricePnrTips: true,
            PNRSayPriceText: '',
            sayPriceSubmitLoading: false,
        };
    },
    components: { airLineBox, DomesticTicketAirportSelector },
    methods: {
        // 获取公告
        getNoticeList() {
            get_flight_notice_list().then(res => {
                this.announcementList = res.data.allFlightNoticeLists;
            })
        },
        // 日期选择
        setEndTime() {
            let _this = this;
            return {
                disabledDate: time => {
                    if (_this.time1) {
                        return time.getTime() < _this.time1;
                    } else {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    }
                }
            };
        },
        goAnnoun(val) {
            window.open(val);
        },
        setEndTime1() {
            let _this = this;
            return {
                disabledDate: time => {
                    if (_this.time2 && this.airType === 2) {
                        return (
                            time.getTime() > _this.time2 ||
                            time.getTime() < Date.now() - 1000 * 3600
                        );
                    } else {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    }
                }
            };
        },
        querySearch(queryString, cb, name) {
            if (!queryString) return;
            var restaurants = this.restaurants;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            results.length === 0 ? (this[name] = "") : "";
            cb(results);
        },
        querySearchForeign(queryString, cb, name) {
            if (!queryString) return;
            var restaurants = this.internationalCitys;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            results.length === 0 ? (this[name] = "") : "";
            cb(results);
        },
        createFilter(queryString) {
            let pattern2 = new RegExp("[A-Za-z]+");
            if (pattern2.test(queryString)) {
                return restaurant => {
                    if (restaurant.spell && restaurant.code) {
                        if (
                            restaurant.spell
                                .toUpperCase()
                                .indexOf(queryString.toUpperCase()) != -1
                        ) {
                            return restaurant;
                        } else if (
                            restaurant.code.indexOf(queryString.toUpperCase()) != -1
                        ) {
                            return restaurant;
                        }
                    } else {
                        return "";
                    }
                };
            } else {
                return restaurant => {
                    if (restaurant.cityName.indexOf(queryString) !== -1) {
                        return restaurant;
                    } else {
                        return "";
                    }
                };
            }
        },

        // 城市转换
        transitionCity() {
            let city = this.starCity;
            this.starCity = this.endCity;
            this.endCity = city;
            let city1 = this.starCity1;
            this.starCity1 = this.endCity1;
            this.endCity1 = city1;
        },
        transitionCity2() {
            let city = this.starTwoCity;
            this.starTwoCity = this.endTwoCity;
            this.endTwoCity = city;
            let city1 = this.starTwoCity1;
            this.starTwoCity1 = this.endTwoCity1;
            this.endTwoCity1 = city1;
        },
        ransitionCity2() {
            let city = this.starTwoCity;
            this.starTwoCity = this.endTwoCity;
            this.endTwoCity = city;
            let city1 = this.starTwoCity1;
            this.starTwoCity1 = this.endTwoCity1;
            this.endTwoCity1 = city1;
        },
        // 获取出发城市
        getstarCity(item) {
            this.starCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.starCity1 = item.name + "(" + item.code + ")";
            // console.log(item, this.starCity);
        },
        getstarTwoCity(item) {
            this.starTwoCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.starTwoCity1 = item.name + "(" + item.code + ")";
            console.log(item);
        },
        // 获取目的城市
        getendCity(item) {
            this.endCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.endCity1 = item.name + "(" + item.code + ")";
            console.log(item);
        },
        getendTwoCity(item) {
            this.endTwoCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.endTwoCity1 = item.name + "(" + item.code + ")";
            console.log(item);
        },
        // 获取点击的城市
        getClickCity(item, data) {
            if (data === "starCity") {
                this.starCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.starCity1 = item.name + "(" + item.code + ")";
                this.starList = false;
                !this.endCity1 ? this.$refs.endCity1.focus() : "";
            }
            if (data === "endCity") {
                this.endCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.endCity1 = item.name + "(" + item.code + ")";
                this.EndList = false;
            }
            if (data === "starTwoCity") {
                this.starTwoCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.starTwoCity1 = item.name + "(" + item.code + ")";
                this.starTwoList = false;
            }
            if (data === "endTwoCity") {
                this.endTwoCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.endTwoCity1 = item.name + "(" + item.code + ")";
                this.EndTwoList = false;
            }
        },
        // 获取焦点显示热门的城市
        CityFocus(data) {
            if (this.starCity1 === "" && data === "starCity") {
                this.starList = true;
                this.EndList = false;
                this.starTwoList = false;
                this.EndTwoList = false;
            }
            if (this.endCity1 === "" && data === "endCity") {
                this.starList = false;
                this.EndList = true;
                this.starTwoList = false;
                this.EndTwoList = false;
            }
            if (this.starTwoCity1 === "" && data === "starTwoCity") {
                this.starList = false;
                this.EndList = false;
                this.starTwoList = true;
                this.EndTwoList = false;
            }
            if (this.endTwoCity1 === "" && data === "endTwoCity") {
                this.starList = false;
                this.EndList = false;
                this.starTwoList = false;
                this.EndTwoList = true;
            }
        },
        noneShow() {
            let Starfocus = document.getElementById("starCity");
            let endCity = document.getElementById("endCity");
            let StarTwofocus = document.getElementById("starTwoCity");
            let endTwoCity = document.getElementById("endTwoCity");
            if (StarTwofocus !== document.activeElement) {
                this.starTwoList = false;
            }
            if (endTwoCity !== document.activeElement) {
                this.EndTwoList = false;
            }
            if (Starfocus !== document.activeElement) {
                this.starList = false;
            }
            if (endCity !== document.activeElement) {
                this.EndList = false;
            }
        },
        // 阻止事件冒泡
        stop() {},
        selectItem(val) {
            this.airlines = val;
        },
        // 获取航司列表
        getAirlines() {
            airlines({ pageSize: 10000 })
                .then(data => {
                    let airlinesList = [
                        ...this.airlinesList,
                        ...data.pageResult.pageData
                    ];
                    airlinesList.map(item => {
                        item.value = "(" + item.airlineCode + ")" + item.airCompanyName;
                    });
                    this.airlinesList = airlinesList;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getInternationalAirlines() {
            internationalAirLines().then(data => {
                // console.log(data)
                let airlinesList = [
                    ...this.internationalAirList,
                    ...data.pageResult.pageData
                ];
                airlinesList.map(item => {
                    item.value = "(" + item.airlineCode + ")" + item.airCompanyName;
                });
                this.internationalAirList = airlinesList;
            });
        },
        // 获取城市接口
        loadAllCity() {
            citys().then(res => {
                let restaurants = [...res.citys];
                let cityList = [];
                for (let i = 0; i < res.citys.length; i++) {
                    if (res.citys[i].airports === false) {
                        cityList.push(res.citys[i]);
                    }
                    if (
                        res.citys[i].isHot === "Y" &&
                        this.HotCity.length < 23 &&
                        res.citys[i].airports === false
                    ) {
                        this.HotCity.push(res.citys[i]);
                    }
                }
                this.cityList = _.chain(cityList)
                    .groupBy(function(m) {
                        return m.initial;
                    })
                    .value();
                this.restaurants = this.setCityName(restaurants, cityList);
            });
        },
        setCityName(restaurants, cityList) {
            let airList = restaurants.filter(item => {
                item.cityName = item.name;
                return item.airports;
            });
            airList.map(item => {
                cityList.map(sitem => {
                    if (item.spell === sitem.spell) {
                        item.cityName = sitem.name;
                    }
                });
            });
            return restaurants;
        },

        // 获取城市接口
        async loadAllInternationalCity() {
            if (this.internationalCity.length != 0) return;
            internationalCity().then(res => {
                let internationalCitys = res.citys;
                let cityList = [];
                for (let i = 0; i < res.citys.length; i++) {
                    if (res.citys[i].airports === false) {
                        cityList.push(res.citys[i]);
                    }
                    if (
                        res.citys[i].isHot === "Y" &&
                        this.HotCity.length < 23 &&
                        res.citys[i].airports === false
                    ) {
                        this.internationalCityHotCity.push(res.citys[i]);
                    }
                }
                this.internationalCity = _.chain(cityList)
                    .groupBy(function(m) {
                        return m.initial;
                    })
                    .value();
                this.internationalCitys = this.setCityName(
                    internationalCitys,
                    cityList
                );
            });
        },
        // 搜索航班列表
        search() {
            this.loading = true;
            this.airTypeShow = true;

            // this.timeRoll = this.time1;
            let params = {
                airType: this.airType, // 航程类型// 航程类型
                time1: this.time1, //  出发时间
                time2: this.time2, //  返回时间
                time2Show: this.time2Show, // 返回时间是否可用
                // timeRoll: this.timeRoll, //滚动条时间
                psgType: this.psgType, //  乘机人类型
                ClassType: this.ClassType, //舱位等级
                airlines: this.airlines //航空公司
            };
            if (this.conditionNavActive === 1) {
                params = Object.assign({}, params, {
                    starCity: this.starCity,
                    starCity1: this.starCity1,
                    endCity: this.endCity,
                    endCity1: this.endCity1
                });
                if (!this.starCity1) {
                    this.starCity1 ? (this.starCity1 = "") : "";
                    this.$refs.startDomesticTicketAirportSelector.$refs.aBaseEntitySelector.focus();
                    return;
                }
                if (!this.endCity1) {
                    this.endCity1 ? (this.endCity1 = "") : "";
                    this.$refs.endDomesticTicketAirportSelector.$refs.aBaseEntitySelector.focus();
                    return;
                }
                if (this.starCity1 == this.endCity1) {
                    this.endCity1 = "";
                    return;
                }
            }
            if (!this.time1) {
                this.$refs.time1.focus();
                return;
            }
            if (!this.time2 && this.airType === 2) {
                this.$refs.time2.focus();
                return;
            }
            if (this.conditionNavActive === 2) {
                params = Object.assign({}, params, {
                    starCity: this.starTwoCity,
                    starCity1: this.starTwoCity1,
                    endCity: this.endTwoCity,
                    endCity1: this.endTwoCity1
                });
                if (!this.starTwoCity1) {
                    this.starTwoCity1 ? (this.starTwoCity1 = "") : "";
                    this.$refs.starTwoCity.focus();
                    return;
                }
                if (!this.endTwoCity1) {
                    this.endTwoCity1 ? (this.endTwoCity1 = "") : "";
                    this.$refs.endTwoCity.focus();
                    return;
                }

                if (this.international.baby && !this.international.adult) {
                    return this.$message({ type: "warning", message: "请选择成人" });
                }
                if (
                    !this.international.baby &&
                    !this.international.adult &&
                    !this.international.child
                ) {
                    return this.$message({ type: "warning", message: "请选择乘客人数" });
                }
                if (!this.international.rank) {
                    return this.$message({ type: "warning", message: "请选择舱位等级" });
                }
                let people;
                let list = this.international;
                list.adult ? (params.adultCount = list.adult) : "";
                list.child ? (params.childCount = list.child) : "";
                list.baby ? (params.babyCount = list.baby) : "";
                people =
                    (params.adultCount || 0) +
                    (params.childCount || 0) +
                    (params.babyCount || 0);
                if (people > 9)
                    return this.$message({
                        type: "warning",
                        message: "最大乘客数量为9人,请重新选择！"
                    });
                params.ClassType = this.international.rank;
                return this.$router.push({
                    name: "international-air-list",
                    query: {
                        airdata: JSON.stringify(params)
                    }
                });
            }

            this.params = params;
            console.log(this.params);
            if (params.time1 && params.starCity && params.endCity) {
                this.$router.push({
                    name: "air-list",
                    query: {
                        airdata: JSON.stringify(this.params)
                    }
                });
            } else {
                this.$router.push({
                    name: "air-list"
                });
            }
        },
        detectionCity() {
            let arrCity = [];
            for (let i in this.cityList) {
                arrCity.push(...this.cityList[i]);
            }
            let code = arrCity.map(item => {
                return item.code;
            });
            if (code.includes(this.starTwoCity) && code.includes(this.endTwoCity)) {
                this.conditionNavActive = 1;
                this.starCity = this.starTwoCity;
                this.starCity1 = this.starTwoCity1;
                this.endCity = this.endTwoCity;
                this.endCity1 = this.endTwoCity1;
                let cityInfo = [
                    "starTwoCity",
                    "starTwoCity1",
                    "endTwoCity",
                    "endTwoCity1"
                ];
                cityInfo.map(name => (this[name] = ""));
            }
        },
        // pnr 导入
        onTipsClick() {
            this.showPnrTips = false;
            this.$refs.pnrTextarea.focus();
        },
        onPnrTextareaBlur() {
            if (this.PNRtext === '') {
                this.showPnrTips = true;
            }
        },
        onPnrSubmit() {
            if (this.PNRtext === '') {
                this.$message({
                    message: '请输入 PNR',
                    type: 'error',
                    offset: 60,
                });
                return;
            }
            this.submitLoading = true;
            buyer_dom_white_pnrImport({
                rtContent: this.PNRtext,
            }).then(data => {
                /*if (data.dfdList[0].policys.length === 0) {
                    this.$alert('未查到相应航班政策', '提示', {
                        confirmButtonText: '确定',
                    });
                    return;
                }*/
                this.$router.push({
                    name: 'flight-pnr',
                    query: { id: data.uuid },
                });
            }).finally(() => {
                this.submitLoading = false;
            });
        },
        // 询价pnr
        onSayPricePnrTipsClick() {
            this.showSayPricePnrTips = false;
            this.$refs.pnrSayPriceTextarea.focus();
        },
        onSayPricePnrTextareaBlur() {
            if (this.PNRSayPriceText === '') {
                this.showSayPricePnrTips = true;
            }
        },
        onSayPricePnrSubmit() {
            if (this.PNRSayPriceText === '') {
                this.$message({
                    message: '请输入 PNR',
                    type: 'error',
                    offset: 60,
                });
                return;
            }
            this.sayPriceSubmitLoading = true;
            // 提交
            let param = {
                "pnrText":this.PNRSayPriceText
            }

            buyer_dom_white_enquiryPnrTextParse(param).then(data => {
                // 跳转
                this.$router.push({
                    name: 'enquiry-reserve',
                    query:{id:data.cacheKey}
                })
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.sayPriceSubmitLoading = false;
            });

        },
        init() {
            this.$refs.startDomesticTicketAirportSelector.init({ placeholder_text: "请选择出发城市" });
        },
        setDefault() {
            this.startAirportParams = {
                code: 'CAN',
                name: '广州'
            }
            this.endAirportParams = {
                code: 'BJS',
                name: '北京'
            }
            this.time1 = moment().valueOf()
            // this.time2 = moment().add(1,'days').valueOf()
        },
    },
    watch: {
        airType() {
            if (this.airType === 1) {
                this.time2Show = true;
            } else {
                this.time2Show = false;

                // 如果没有选中时间，则加上默认时间
                if (!this.time2) {
                    this.time2 = moment().add(1,'days').valueOf()
                }

            }
            if (this.airType === 2 && this.time1 > this.time2) {
                this.time2 = moment(this.time1).add(1,'days').valueOf();
            }
            this.airTypeShow = false;
            this.itemOne = [];
        },
        // 出发城市1
        starCity1(newVal) {
            let Starfocus = document.getElementById("starCity");
            if (newVal !== "") {
                this.starList = false;
            }
            if (Starfocus === document.activeElement && newVal === "") {
                this.starList = true;
            }
        },
        // 目的城市1
        endCity1(newVal) {
            let endCity = document.getElementById("endCity");
            if (newVal !== "") {
                this.EndList = false;
            }
            if (endCity === document.activeElement && newVal === "") {
                this.EndList = true;
            }
        },
        // 出发城市2
        starTwoCity1() {
            let Starfocus = document.getElementById("starTwoCity");
            if (this.starTwoCity1 !== "") {
                this.starTwoList = false;
                this.endTwoCity1 ? this.detectionCity() : "";
            }
            if (Starfocus === document.activeElement && this.starTwoCity1 === "") {
                this.starTwoList = true;
            }
        },

        // 目的城市2
        endTwoCity1() {
            let endCity = document.getElementById("endTwoCity");
            if (this.endTwoCity1 !== "") {
                this.EndTwoList = false;
                this.detectionCity();
            }
            if (endCity === document.activeElement && this.endTwoCity1 === "") {
                this.EndTwoList = true;
            }
        },

        // 获取国内出发城市
        startAirportParams(newVal) {
            console.log(newVal)
            this.starCity = newVal.code
            this.starCity1 = newVal.name
        },
        // 获取国内目的城市
        endAirportParams(newVal) {
            console.log(newVal)
            this.endCity = newVal.code
            this.endCity1 = newVal.name
        },

    },
    mounted() {
        this.init()
        this.getNoticeList()
        this.loadAllCity();
        this.loadAllInternationalCity();
        this.setDefault()

    },
    created() {
        const tab = this.$route.query.tab
        if (tab) {
            this.conditionNavActive = parseInt(tab)
        }
        this.getAirlines();
        this.getInternationalAirlines();
    }
};

/*export default {
    data() {
        return {}
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {}
}*/
